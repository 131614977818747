<template>
  <div class="box-content">
    <h2>Ajouter un service</h2>
    <div class="info_grid">
      <div class="pt-10">
        <text-input
          :errors="errors.service"
          v-model="form.service"
          label="Nom du service"
        />
      </div>
      <div class="pt-10">
        <label>Client</label>
        <v-select
          :options="customers"
          placeholder="Selectionner"
          v-model="form.customer_id"
          label="name"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.customer_id"
          :class="errors.customer_id.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.customer_id[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <label>Facturation</label>
        <v-select
          :options="ratings"
          placeholder="Selectionner"
          v-model="form.rating"
          label="label"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.rating"
          :class="errors.rating.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.rating[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <label>Permissions</label>
        <v-select
          :options="permissions"
          multiple
          placeholder="Selectionner"
          v-model="form.permissions"
          label="description"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.permissions"
          :class="errors.permissions.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.permissions[0]
          }}</strong>
        </div>
      </div>
    </div>
    <div class="info_grid_t">
      <div class="pt-10">
        <label>Opérateurs</label>
        <v-select
          :options="operators"
          multiple
          placeholder="Selectionner"
          v-model="form.operator"
          label="bankcode"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.operator"
          :class="errors.operator.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.operator[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <text-input v-model="form.description" label="Description du service" />
      </div>
    </div>
    <div class="pt-10">
      <label class="checkbox"
        ><input v-model="form.active" type="checkbox" /> Activer le
        service</label
      >
    </div>
    <div class="pt-10 d-flex-right">
      <button
        @click="addService"
        :disabled="loading"
        :class="
          loading
            ? 'button is-responsive is-loading is-primary'
            : 'button is-responsive is-primary'
        "
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput";
const Defaut_form = {
  service: "",
  active: true,
  description: "",
  customer_id: "",
  rating: "",
  operator: [],
  permissions: [],
};
export default {
  name: "addService",
  components: { TextInput },
  data: () => ({
    loading: false,
    form: {
      service: "",
      active: true,
      description: "",
      customer_id: "",
      rating: "",
      operator: [],
      permissions: [],
    },
    errors: [],
  }),

  computed: {
    ...mapGetters({
      customers: `${TYPES.modules.UTILS}/${TYPES.getters.GET_MCP}`,
      permissions: `${TYPES.modules.UTILS}/${TYPES.getters.GET_PERMISSIONS}`,
      ratings: `${TYPES.modules.UTILS}/${TYPES.getters.GET_RATING}`,
      operators: `${TYPES.modules.UTILS}/${TYPES.getters.GET_OPERATOR}`,
    }),
  },

  mounted() {
    this.getCustomers();
    this.getPermissions();
    this.getRatings();
    this.getOperators();
  },

  methods: {
    getCustomers() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_MCP}`
      );
    },

    getPermissions() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_PERMISSIONS}`
      );
    },

    getRatings() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_RATING}`
      );
    },

    getOperators() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_OPERATOR}`
      );
    },

    addService() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.ADD_SERVICE}`,
          this.form
        )
        .then((res) => {
          this.loading = false;
          this.form = { ...Defaut_form };
          this.errors = [];
          this.$toast.success(`Le service a été ajouté`);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.info_grid_t {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
}
</style>